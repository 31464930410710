import { useEffect, useRef, useState } from 'react';
import { Link, Typography } from '@packages/shared';
import { useIntl } from 'react-intl';
import { useTracking } from '@packages/tracking';
import config from '@packages/config';
import { usePathname } from 'next/navigation';
import { messages } from '../PromotionBanner.messages';
import type { Promotion } from '../types';
import { PromotionAccordionTemplate } from './PromotionAccordionTemplate';

export const PromotionAsAccordion = ({
  name,
  title,
  description,
  feedbackDescription,
  cartCode,
  textsCode,
  feedbackTitle,
  isActive,
  defaultExpanded,
  setActivePromotionCode,
  isPaybackPromotion,
}: Promotion & {
  isActive: boolean;
  defaultExpanded?: boolean;
  setActivePromotionCode: (
    cartCodeToActivate: string | null,
    textsCodeToActivate: string | null,
    isPaybackPromotion?: Promotion['isPaybackPromotion'],
  ) => Promise<void>;
}): JSX.Element => {
  const { formatMessage } = useIntl();
  const displayTitle = isActive && feedbackTitle ? feedbackTitle : title;
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);
  const dispatchGTMEvent = useTracking();

  // INSPIRE-3692 - Close the accordion when the user navigates to a different page
  const pathname = usePathname();
  const lastPathnameRef = useRef(pathname);
  useEffect(() => {
    if (lastPathnameRef.current !== pathname) {
      setIsExpanded(false);
      lastPathnameRef.current = pathname;
    }
  }, [pathname]);

  return (
    <PromotionAccordionTemplate
      summaryContent={displayTitle}
      toggleFunction={() => {
        setIsExpanded(!isExpanded);

        if (!isActive && cartCode) {
          setActivePromotionCode(cartCode, textsCode, isPaybackPromotion);
        }

        dispatchGTMEvent({
          event: 'trackPromoBanner',
          eventLabel: 'promo-banner',
          eventValue: !isExpanded ? `promobanner_open_${name}` : `promobanner_closed1_${name}`,
        });
      }}
      detailscontent={
        <>
          <Typography
            variant="body2"
            sx={{ fontWeight: config.styleVariant === 'dark' ? 'normal' : 'bold' }}
            paragraph
          >
            {description}
          </Typography>
          <Typography variant="body3" paragraph>
            {feedbackDescription}
          </Typography>
          <Typography variant="body2">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              href="/"
              color="inherit"
              onClick={(event) => {
                event.preventDefault();
                setIsExpanded(false);

                dispatchGTMEvent({
                  event: 'trackPromoBanner',
                  eventLabel: 'promo-banner',
                  eventValue: `promobanner_closed1_${name}`,
                });
              }}
            >
              {formatMessage(messages.nowShopping)}
            </Link>
          </Typography>
        </>
      }
      defaultExpanded={defaultExpanded}
      isExpanded={isExpanded}
      isPaybackPromotion={isPaybackPromotion}
    />
  );
};
